<template>
  <div>
    <b-card title="Añadir Elementos" class="text-center">
      <b-row class="mb-4">
       <div style="width: 100% ;">
        <table id="registro" class="table" :style="'height :'+ register_y +'px'" v-if="register">
          <tr>
            <td :colspan="vdivisions" style="border : 1px solid black">
                <div class="d-flex innerDiv1" style="height : 100% ; flex-direction : column; justify-content: space-evenly">
                </div>          
            </td>
          </tr>              
        </table>        
          <table id="esquema" class="table table-bordered" :style="'height :'+ table_y +'px'">
            <tr>
              <td v-for="vdivision,index in dessign" :key="index" :style="'width : '+ vdivision.percent+'%'">
                <div class="d-flex outerDiv1" style="">
                  <div v-for="hdivision,key in vdivision.rows" :key="key" 
                    :style="'height : '+ hdivision.percent +'%'"
                  > 
                  <div v-if="hdivision.element" :style="'background-image : url(\'' +  $http.defaults.baseURL + 'storage/app/private/' +  hdivision.element.image+'\')'" class="d-flex innerDiv1">
                      <b-button
                        v-b-toggle.sidebar-right
                        variant="default"
                        @click="setId(index,key)"
                      >
                        <feather-icon icon="PlusSquareIcon" size= 24 />
                      </b-button>
                  </div>
                  <div v-else  class="d-flex innerDiv1">
                      <b-button
                        v-b-toggle.sidebar-right
                        variant="default"
                        @click="setId(index,key)"
                      >
                        <feather-icon icon="PlusSquareIcon" size= 24 />
                      </b-button>
                  </div>
                  </div>
                </div>
              </td>
            </tr>
          </table>  
        </div>
      </b-row>
      <b-sidebar
        id="sidebar-right"
        bg-variant="white"
        v-model="open"
        right
        backdrop
        shadow
      >
        <template #default="{ hide }">
          <div class="p-3">
            <h4 id="sidebar-no-header-title">Añadir Elemento</h4>
            <v-select
              v-model="category"
              dir="ltr"
              label="title"
              @input="loadElements"
              :options="categories_options"

            />
              <b-row class="my-1" v-if="!is_selected">
                <b-col v-for="element,index in elements" :key="index" cols="6">
                  <div v-on:click="chooseElement(element)">                 
                    <b-img
                      fluid
                      :src="$http.defaults.baseURL + 'storage/app/private/' +  element.image"
                    >
                    </b-img>
                    {{element.name}}
                  </div>
                </b-col>
              </b-row>
              <b-row v-else>
                <b-col class="mb-2" cols="12">
                  <h4 class="text-center mt-2">{{El_selected.name}}</h4>
                  <b-img
                    fluid
                    :src="$http.defaults.baseURL + 'storage/app/private/' +  El_selected.image"
                  >
                  </b-img>
                </b-col>
                <b-col cols="12" v-for="option,index in El_selected.options" :key="index">
                  <b-form-group  v-if="!option.has_value" :label="option.name">
                    <b-form-input
                        v-model="Op_selected[index].value"
                        class="form-control-merge text-center"
                      />
                  </b-form-group >
                  <b-form-group :label="option.name" v-else >
                    <b-form-select v-model="Op_selected[index].value">
                      <b-form-select-option :value="null" disabled>Seleccione una opción</b-form-select-option>
                      <b-form-select-option :value="radio.value" v-for="radio,key in option.values" :key="key">{{radio.value}}</b-form-select-option>
                    </b-form-select>
                  </b-form-group>
                </b-col>
                <b-col cols="12">
                  <hr class="w-100">
                </b-col>
                <b-col cols="12">
                  <b-button class="mb-2" variant="success" block @click="addElement">Añadir</b-button>
                </b-col>
              </b-row>
            <b-button variant="primary" block @click="closeSidebar">Cerrar</b-button>
          </div>
        </template>
      </b-sidebar>      
      <b-button type="button" variant="success" block @click="next"> Continuar </b-button>
    </b-card>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import vSelect from "vue-select";
import {
  BCard,
  BCol,
  BRow,
  BButton,
  BImg,
  BFormSelect,
  BFormSelectOption,
  BFormInput,
  BFormGroup,  
  BSidebar,
  VBToggle,
} from "bootstrap-vue";
export default {
  components: {
    BCard,
    BCol,
    BRow,
    BButton,
    BSidebar,
    BFormInput,
    BImg,
    BFormSelect,
    BFormSelectOption,
    BFormGroup,
    vSelect,
  },
    directives: {
    'b-toggle': VBToggle
  },
  data() {
    return {
      last_options : {},
      dessign : [],
      eSelected : [0,0],
      elements : [],
      is_selected : false,
      open : false,
      category : null,
      categories_options : [],
      El_selected : null,
      Op_selected : null,
      table_y : null,
      register_y : null
    };
  },
  created () {
    this.dessign = this.divisions
    for (let i = 0; i < this.dessign.length; i++) {
      this.dessign[i].percent = (this.dessign[i].width / this.total_width) * 100
      for (let j = 0; j < this.dessign[i].rows.length; j++) {
        this.dessign[i].rows[j].percent = (this.dessign[i].rows[j].height  / this.total_height) * 100;
      }      
    }
    this.$http.get("/api/getCategories").then((response) => {
         this.categories_options = response.data
    }); 
  },
  mounted () {
    let clientWidth = document.getElementById('esquema').clientWidth;
    this.table_y = (clientWidth * this.total_height) / this.total_width;
    this.register_y = (clientWidth * this.register) / this.total_width;    
  },    
  methods : {
    next() {
      this.$router.push('/sumary');
    },
    reset() {
      this.$router.push('/');
    },
    loadElements() {
      this.is_selected = false;
      this.$http.get("/api/getElements?category_id=" + this.category.id).then((response) => {
        this.elements = response.data
      }); 
    },
    setId(index,key){
      this.eSelected = [index,key]
    },
    chooseElement(element){
      this.El_selected = element
      this.Op_selected = [];
      for (let i = 0; i < this.El_selected.options.length; i++) {
        let t_op = {id : this.El_selected.options[i].id,name : this.El_selected.options[i].name, value : null}
        if(this.last_options.hasOwnProperty(this.El_selected.options[i].name)){
         t_op.value = this.last_options[this.El_selected.options[i].name]
        }        
        this.Op_selected.push(t_op);
      }
      this.is_selected = true;
    },
    addElement(){
      this.dessign[this.eSelected[0]].rows[this.eSelected[1]].element = {
        id : this.El_selected.id, image : this.El_selected.image , name : this.El_selected.name
      }
      this.dessign[this.eSelected[0]].rows[this.eSelected[1]].options = this.Op_selected;
      for (let i = 0; i < this.Op_selected.length; i++) {
        const element = this.Op_selected[i];
        this.last_options[element.name] = element.value;
      }

      this.is_selected = false;
      this.open = false;
      this.$forceUpdate();
    },
    closeSidebar()
    {
      this.open = false;
      this.is_selected = false;
      this.El_selected = null
      this.Op_selected = null
      }
    },
    computed: {
    ...mapState({
      register : state => state.ventanas.complements.registro_medida,      
      divisions: state => state.ventanas.divisions,
      total_width : state => state.ventanas.dimensions.width,
      total_height : state => state.ventanas.dimensions.height,
    }),
  }
};
</script>
<style>
.innerDiv1{
  height: 100%;
  align-items : center;
  border : 1px solid black;
  padding : 5px ;
  background-color : lightgray !important;
  justify-content: center;
  background-repeat: no-repeat;
  background-position: center;
  background-size: 100% 100%;
}
.outerDiv1{
  height : 100% ;
  flex-direction : column;
  justify-content: space-evenly
}
</style>